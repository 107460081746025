import { ForbiddenError, InvalidCredentialsError, UnexpectedError } from '@/domain/errors'
import { Authentication } from '@/domain/usecases'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols/http'

export class RemoteAuthentication implements Authentication {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<Authentication.Result>
  ) {}

  async auth (params: Authentication.Params): Promise<Authentication.Result> {
    const authParams: HttpRequest = {
      url: `${this.url}/api/token`,
      method: 'post',
      body: {
        ...params,
        grant_type: 'password',
        portal: true
      },
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    }

    const { statusCode, body } = await this.httpClient.request(authParams)

    switch (statusCode) {
      case HttpStatusCode.ok: return body
      case HttpStatusCode.badRequest: throw new ForbiddenError(body?.error_description)
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError(body?.error_description)
      default: throw new UnexpectedError()
    }
  }
}
