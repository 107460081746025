import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { useImage, useThemeClient } from '@/presentation/hooks'
import { logoEmpresaState } from '@/presentation/components'

type LogoThemeProps = {
  height?: number
  width?: number
  minHeight?: number
}

export const LogoTheme: React.FC<LogoThemeProps> = (props) => {
  const { getImage } = useImage()
  const { getThemeClient } = useThemeClient()
  const themeClient = getThemeClient()
  const [logoEmpresa, setLogoEmpresa] = useRecoilState(logoEmpresaState)
  const isSvg = !logoEmpresa

  useEffect(() => {
    if ((themeClient.imageLogo) && (!__DEV__) && (logoEmpresa !== themeClient.imageLogo)) {
      void getImage(themeClient.imageLogo, 'logo')
        .then(logo => { setLogoEmpresa(logo) })
        .catch(console.error)
    }
  }, [themeClient.imageLogo])

  return (
    <img
      style={{
        width: props.width ?? 'auto',
        height: props.height ?? 'auto',
        minWidth: '60px',
        maxWidth: isSvg ? '180px' : 'auto',
        minHeight: props.minHeight ?? '',
        marginRight: 8,
        objectFit: 'contain'
      }}
      src={logoEmpresa}
    />
  )
}
