import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Box, Text, CardRow, Card } from '@/presentation/components'
import { isPrintingServiceOrderDetailState, serviceOrderDetailState } from '@/presentation/pages/ServiceOrderDetail/components'
import { theme } from '@/presentation/styles'
import { useMediaQueries } from '@/presentation/hooks'
import { styles } from '../../styles'
import { selectedOsImageViewState } from '../OsImageView/atom'

export const OsImages: React.FC = () => {
  const { isMobile } = useMediaQueries()
  const serviceOrderDetails = useRecoilValue(serviceOrderDetailState)
  const setImageView = useSetRecoilState(selectedOsImageViewState)
  const isPrinting = useRecoilValue(isPrintingServiceOrderDetailState)

  return (
    <>
      {!!serviceOrderDetails.imagensGerais?.length &&
        <>
          <Text style={styles.pageInfo}>Anexos</Text>
          <CardRow>
            {serviceOrderDetails
              .imagensGerais
              .filter(image => !(/PERGUNTA\d+\.png$/.test(image.nomeArquivo)))
              .map((image, index) => (
                <Card
                  key={index}
                  onPress={() => { setImageView({ uri: image.caminhoArquivo, description: image.nomeArquivo }) }}
                  style={{
                    ...styles.cardBase,
                    backgroundColor: theme.colors.backgroundCard,
                    padding: isMobile ? 4 : 8,
                    ...(isPrinting && { boxShadow: 'none' })
                  }}
                >
                  <Card.Content style={styles.cardContent}>
                    <Box style={styles.imageContainer}>
                      <img style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain'
                      }} src={image.caminhoArquivo} alt={image.nomeArquivo} />
                    </Box>
                  </Card.Content>
                </Card>
              ))
            }
          </CardRow>
        </>
      }
    </>
  )
}
