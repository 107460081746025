import React, { useCallback } from 'react'
import { ScrollView } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useFocusEffect } from '@react-navigation/native'
import { useRecoilState, useRecoilValue } from 'recoil'
import { GetMoniUsers, GetRemotyUsers, GetSigmaCloudUsers, GetSigmaCloudUsersParams, GetSowillUsers, GetSowillUsersParams } from '@/domain/usecases'
import { ClientMonitoringType } from '@/domain/models'
import { Box, CardList, PageHeader, Stack } from '@/presentation/components'
import { useAuth, useErrorHandler, useMediaQueries } from '@/presentation/hooks'
import { theme } from '@/presentation/styles'
import { CardUser, userListState, isLoadingUserEventsState, SelectRemotyEventCompany, SelectSigmaCloudKey, sowillIdentifierState, SelectSowillKey, selectSigmaCloudKeyData, remotyFilterState, userListHasError } from '@/presentation/pages/Events/components'
import { layoutStyles } from '@/presentation/styles/globals'

type UserEventListProps = {
  getMoniUsers: GetMoniUsers
  getRemotyUsers: GetRemotyUsers
  getSowillUsers: GetSowillUsers
  getSigmaCloudUsers: GetSigmaCloudUsers
}

export const UserEventList: React.FC<UserEventListProps> = (props) => {
  const { getClient } = useAuth()
  const handleError = useErrorHandler()
  const { isMobile } = useMediaQueries()
  const client = getClient()

  const [hasError, setHasError] = useRecoilState(userListHasError)
  const { remotyIdEquipamento } = useRecoilValue(remotyFilterState)
  const currentSigmaKey = useRecoilValue(selectSigmaCloudKeyData)
  const [isLoading, setLoading] = useRecoilState(isLoadingUserEventsState)
  const [userList, setUserList] = useRecoilState(userListState)
  const sowillIdentifier = useRecoilValue(sowillIdentifierState)

  useFocusEffect(
    useCallback(() => {
      const conditions = {
        [ClientMonitoringType.REMOTY]: remotyIdEquipamento && remotyIdEquipamento !== 0,
        [ClientMonitoringType.MONI]: client.tipoConexao === 1,
        [ClientMonitoringType.SIGMA_CLOUD]: !!(currentSigmaKey.codCliente),
        [ClientMonitoringType.SIGMA_DESK]: !!(currentSigmaKey.codCliente),
        [ClientMonitoringType.SO_WILL]: !!(sowillIdentifier.codInternoChave)
      }[client.tipoConexao]

      if (conditions && !hasError) { getEventUser() }
    }, [
      client.codCliente,
      remotyIdEquipamento,
      currentSigmaKey,
      currentSigmaKey.codCliente,
      sowillIdentifier.codInternoChave
    ])
  )

  const errorHandler = (error: Error): void => {
    handleError(error)
    setHasError(true)
  }

  const handleGetMoniUserList = (): void => {
    const params: GetMoniUsers.Params = {
      unidade: client.unidade,
      empresaMoni: client.empresaMonitoramento,
      codCliente: client.codCliente
    }

    setLoading(true)

    props.getMoniUsers
      .getUsers(params)
      .then(setUserList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const handleGetRemotyUserList = (): void => {
    const params: GetRemotyUsers.Params = {
      idEquipamento: remotyIdEquipamento,
      empresaMonitoramento: client.empresaMonitoramento
    }

    setLoading(true)

    props.getRemotyUsers
      .getUsers(params)
      .then(setUserList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const handleGetSowillUserList = (): void => {
    const params: GetSowillUsersParams = {
      idCliente: sowillIdentifier.idCliente,
      codInternoChave: sowillIdentifier.codInternoChave
    }

    setLoading(true)

    props.getSowillUsers
      .getUsers(params)
      .then(setUserList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const handleGetSigmaCloudUserList = (): void => {
    const params: GetSigmaCloudUsersParams = {
      codCentral: currentSigmaKey.codInterno,
      empresaSigma: currentSigmaKey.empresaSigma
    }

    setLoading(true)

    props.getSigmaCloudUsers
      .getUsers(params)
      .then(setUserList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const getEventUser = {
    [ClientMonitoringType.MONI]: handleGetMoniUserList,
    [ClientMonitoringType.REMOTY]: handleGetRemotyUserList,
    [ClientMonitoringType.SIGMA_CLOUD]: handleGetSigmaCloudUserList,
    [ClientMonitoringType.SIGMA_DESK]: () => { },
    [ClientMonitoringType.SO_WILL]: handleGetSowillUserList
  }[client.tipoConexao]

  return (
    <Box style={{ ...layoutStyles.pageContent }}>
      <PageHeader
        text='Usuarios'
        icon={<MaterialCommunityIcons name='alarm-light-outline' size={24} color={theme.colors.grey} />}
      />

      <Stack
        direction={isMobile ? 'column-reverse' : 'row'}
        mr={window.innerWidth <= 1380 ? 36 : isMobile ? 'auto' : 0}
        ml={window.innerWidth <= 1380 ? 36 : isMobile ? 'auto' : 0}
        justify='between'
        items='center'
        spacing={8}
      >
        {[ClientMonitoringType.REMOTY].includes(client.tipoConexao) && <SelectRemotyEventCompany />}
        {[ClientMonitoringType.SIGMA_CLOUD].includes(client.tipoConexao) && <SelectSigmaCloudKey />}
        {[ClientMonitoringType.SO_WILL].includes(client.tipoConexao) && <SelectSowillKey />}
      </Stack>

      <ScrollView style={{ padding: '1rem' }}>
        <CardList
          isEmpty={!userList.length}
          isLoading={isLoading}
          textLoading='Buscando informações de usuarios'
        >
          {userList?.map(user => (
            <CardUser
              key={`cardUser-${user.nome.replaceAll(' ', '')}`}
              user={user}
            />
          ))}
        </CardList>
      </ScrollView>
    </Box>
  )
}
