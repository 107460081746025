import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { GetTicketPix } from '@/domain/usecases'
import { TicketModel } from '@/domain/models'
import { DateAdapter } from '@/data/protocols'
import { Box, Button, Text, ModalBase, Clipboard, QRCodeSvg, Loading, Chip, HStack, VStack, TextInput } from '@/presentation/components'
import { useErrorHandler, useFormat, useNotify, useTheme, useThemeClient } from '@/presentation/hooks'
import { isLoadingTicketPixState, isOpenTicketPixState, selectedTicketPixState } from '@/presentation/pages/Financial/components'
import { styles } from './styles'

type ModalTicketPixProps = {
  getTicketPix: GetTicketPix
  dateAdapter: DateAdapter
}

export const ModalTicketPix: React.FC<ModalTicketPixProps> = (props) => {
  const theme = useTheme()
  const notify = useNotify()
  const { formatCurrency } = useFormat()
  const { getThemeClient } = useThemeClient()
  const handleError = useErrorHandler(() => handleClose())
  const [selectedTicketPix, setCurrentTicketPix] = useRecoilState(selectedTicketPixState)
  const [isOpen, setOpen] = useRecoilState(isOpenTicketPixState)
  const [isLoading, setLoading] = useRecoilState(isLoadingTicketPixState)
  const [pix, setPix] = useState('')

  const [ticket, setTicket] = useState<TicketModel>()
  const isHttps = window.location.protocol === 'https:'

  const themeClient = getThemeClient()

  useEffect(() => {
    if (selectedTicketPix?.codInterno) {
      handleGetPix()
      setTicket(selectedTicketPix)
    } else {
      setPix('')
    }
  }, [selectedTicketPix])

  const handleGetPix = (): void => {
    setLoading(true)

    const params: GetTicketPix.Params = {
      codInternoDuplicata: selectedTicketPix.codInterno,
      codigoBanco: selectedTicketPix.codBanco,
      pixUrl: selectedTicketPix.pixUrl
    }

    props.getTicketPix
      .getPix(params)
      .then(pix => setPix(pix))
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  const handleCopyPix = (): void => {
    if (isHttps) {
      notify.show('O pix foi copiado para área de transferência')
      Clipboard.setStringAsync(pix)
      handleClose()
    } else {
      const input = document.getElementById('pix-code')
      input?.focus()
    }
  }

  const handleClose = useCallback((): void => {
    setOpen(false)
    setCurrentTicketPix(null as unknown as TicketModel)
  }, [])

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        {isLoading
          ? <Loading text={`Gerando PIX boleto ${ticket?.numero ?? ''}`} />
          : <Box style={styles.content}>
            <VStack style={{ width: '100%' }}>
              <HStack spacing={6} justify='between'>
                <Text style={{ fontSize: 18, fontWeight: '600' }}>
                  Boleto via PIX
                </Text>
                <Chip text={`Nº ${ticket?.numero?.padStart(4, '0') ?? ''}`} />
              </HStack>
              <Text variant="h6" style={{ fontSize: 14, color: theme.colors.grey }}>
                Vencimento {ticket?.vencimento && props.dateAdapter.format(ticket?.vencimento)}
              </Text>
              <Text variant="h6" style={{ fontSize: 14, color: theme.colors.grey }}>
                Valor {ticket?.valorDuplicata && formatCurrency(ticket?.valorDuplicata)}
              </Text>
              <Text variant="h6" style={{ fontSize: 14, color: theme.colors.grey }}>
                Valor Atualizado {ticket?.valorAtualizado && formatCurrency(ticket?.valorAtualizado)}
              </Text>
            </VStack>
            {!!pix &&
              <>
                <QRCodeSvg
                  value={pix}
                  size={240}
                  logo={require('../../../../../../assets/logo-color.svg')}
                  logoSize={48}
                  logoBackgroundColor='#ffffff'
                />
                <TextInput
                  nativeID='pix-code'
                  testID='pix-code'
                  value={pix}
                  selectTextOnFocus
                  editable={false}
                  helperText={isHttps ? '' : 'Copie manualmente o código acima'}
                />
              </>
            }
            <HStack fill style={{
              ...styles.buttonArea
            }}>
              <Button
                color={theme.colors.shadow}
                variant="text"
                title="FECHAR"
                testID='pix-fechar'
                onPress={handleClose}
                disabled={isLoading}
              />
              <Button
                title={isHttps ? 'COPIAR PIX' : 'SELECIONAR CÓDIGO'}
                testID='copiar-copiar'
                onPress={handleCopyPix}
                disabled={isLoading || !selectedTicketPix || !pix}
                color={themeClient.mainColor}
                tintColor={theme.colors.white}
                trailing={isHttps ? <MaterialCommunityIcons color={theme.colors.white} size={18} name="content-copy" /> : false}
              />
            </HStack>
          </Box>
        }
      </Box>
    </ModalBase>
  )
}
