import React, { useEffect, useState } from 'react'
import { GestureResponderEvent, SafeAreaView, TextInput as NativeTextInput } from 'react-native'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { Checkbox, TextInput } from 'react-native-paper'
import { Authentication, ForgotPassword, GetUserByDocument } from '@/domain/usecases'
import { LogoTheme } from '@/presentation/components/LogoTheme'
import { NavigationRoute } from '@/main/config/router-navigation'
import { KEY_ACCESS_PASSWORD, useAuth, useNotify, useTheme, useThemeClient } from '@/presentation/hooks'
import { BackgroundTheme, Box, Button, Divider, HStack, NavigationPagesProps, SocialMidias, Text, VStack, isOpenUpdatePasswordState, localStorageState, socialMediaState } from '@/presentation/components'
import { ModalForgotPassword, ModalUserSelect } from '@/presentation/pages/Login/components'
import { styles } from './styles'
import * as State from '@/presentation/pages/Login/components/atoms'
import * as GeneralState from '@/presentation/components/Atoms'
import { version } from '../../../../package.json'

type LoginAuthenticateProps = {
  username: string
  password: string
}

type LoginProps = NavigationPagesProps & {
  getUser: GetUserByDocument
  authentication: Authentication
  forgotPassword: ForgotPassword
}

export const Login: React.FC<LoginProps> = (props) => {
  const theme = useTheme()
  const notify = useNotify()
  const { isAdmin, login, getSaveUser, saveUser, isPasswordSecure } = useAuth()
  const { getThemeClient } = useThemeClient()
  const [state, setState] = useRecoilState(State.loginState)
  const [isLoading, setLoading] = useRecoilState(State.isLoadingState)
  const [isSelected, setSelection] = useState(false)
  const setModalUserSelect = useSetRecoilState(State.usersSelectState)
  const setOpenUserSelect = useSetRecoilState(State.isOpenUserSelect)
  const setOpenForgotPassword = useSetRecoilState(State.isOpenForgotPasswordState)
  const setOpenUpdatePassword = useSetRecoilState(isOpenUpdatePasswordState)
  const prospectConfigs = useRecoilValue(GeneralState.prospectConfigsState)
  const localStorageAdapter = useRecoilValue(localStorageState)
  const prospectInterestArea = useRecoilValue(GeneralState.prospectInterestAreaState)
  const socialMedia = useRecoilValue(socialMediaState)
  const reset = useResetRecoilState(State.loginState)

  const savedUser = getSaveUser()
  const themeClient = getThemeClient()

  const handleChangeInput = (name: keyof LoginAuthenticateProps, value: string): void => {
    setState(currentValues => ({ ...currentValues, [name]: value }))
  }

  const handleError = (error: Error): void => {
    notify.show(error.message, { variant: 'error' })
  }

  const handleCheckSecurityPassword = (): void => {
    if (!isPasswordSecure(state.password) && !isAdmin()) {
      localStorageAdapter.set(KEY_ACCESS_PASSWORD, 'insecure_password')
      setOpenUpdatePassword(!isPasswordSecure(state.password))
      reset()
    }
  }

  const handleSubmit = (event?: GestureResponderEvent): void => {
    event?.preventDefault()

    if (!state.username) {
      handleError(new Error('O usuário deve ser informado'))
      return
    }

    if (!state.password) {
      handleError(new Error('A senha deve ser informada'))
      return
    }

    const dataAuth: Authentication.Params = {
      username: state.username,
      password: state.password
    }

    const accessWithDocument = dataAuth.username.replace(/\.|-/gm, '').replace(/\D/g, '').length > 10
    if (accessWithDocument && !dataAuth.username.includes('@')) {
      loginWithDocument(dataAuth)
    } else {
      loginWithCode(dataAuth)
    }
  }

  const loginWithCode = (params: Authentication.Params): void => {
    setLoading(true)
    props.authentication
      .auth(params)
      .then(async authToken => {
        login(authToken)
        reset()
        if (params.username.toUpperCase() !== 'ADMIN' || !params.username.endsWith('@insidesistemas.com.br')) {
          handleCheckSecurityPassword()
          saveUser({
            username: isSelected ? params.username : '',
            saveUsername: isSelected
          })
        }
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  const loginWithDocument = (params: Authentication.Params): void => {
    setLoading(true)
    props.getUser
      .getUsersByDocument({
        usuario: params.username,
        senha: params.password
      })
      .then(clients => {
        if (clients?.length === 1) {
          loginWithCode({
            username: clients[0].codCliente.toString(),
            password: params.password
          })
          handleCheckSecurityPassword()
        } else {
          setOpenUserSelect(true)
          setModalUserSelect(clients)
        }
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (savedUser.saveUsername) {
      setState(currentState => ({ ...currentState, username: savedUser.username }))
      setSelection(true)
    }
  }, [])

  return (
    <SafeAreaView style={{ ...styles.container, backgroundColor: themeClient.mainColor }}>
      <BackgroundTheme />

      <Box
        style={{
          ...styles.loginContent,
          backgroundColor: themeClient.mainColor,
          borderColor: theme.colors.greyDarken2
        }}
      >
        <LogoTheme height={100}/>
        <Box style={styles.form}>
          <Text color={themeClient.loginColor} style={{ textAlign: 'center' }} variant="h6">Portal do Cliente</Text>
          <TextInput
            theme={{ colors: { onSurfaceVariant: theme.colors.white } }}
            textColor={theme.colors.white}
            underlineColor={theme.colors.white}
            underlineColorAndroid={theme.colors.white}
            activeUnderlineColor={theme.colors.white}
            style={styles.input}
            label="Usuário"
            testID='login-usuario'
            disabled={isLoading}
            onChangeText={(text) => handleChangeInput('username', text)}
            value={state.username}
            render={(props) =>
              <NativeTextInput
                {...props}
                onKeyPress={(event: any) => {
                  event.key.toString().toLowerCase() === 'enter' && handleSubmit()
                }}
              />
            }
          />
          <TextInput
            theme={{ colors: { onSurfaceVariant: theme.colors.white } }}
            textColor={theme.colors.white}
            underlineColor={theme.colors.white}
            underlineColorAndroid={theme.colors.white}
            activeUnderlineColor={theme.colors.white}
            style={styles.input}
            disabled={isLoading}
            label="Senha"
            testID='login-senha'
            secureTextEntry
            onChangeText={text => handleChangeInput('password', text)}
            value={state.password}
            render={(props) =>
              <NativeTextInput
                {...props}
                onKeyPress={(event: any) => {
                  event.key.toString().toLowerCase() === 'enter' && handleSubmit()
                }}
              />
            }
          />
          <Box style={styles.checkboxArea}>
            <Text color={theme.colors.white} variant="subtitle1">
              Salvar usuário
            </Text>
            <Checkbox
              uncheckedColor={themeClient.buttonSave}
              status={isSelected ? 'checked' : 'unchecked'}
              color={themeClient.buttonSave}
              onPress={() => setSelection(!isSelected)}
            />
          </Box>
          <Button
            style={{ width: '100%' }}
            color={themeClient.buttonSave}
            title="LOGIN"
            testID='button-login'
            onPress={handleSubmit}
            disabled={isLoading}
          />
          <HStack center>
            <Text
              color={theme.colors.white}
              variant="subtitle1"
              testID='button-esqueci-senha'
              onPress={() => setOpenForgotPassword(true)}
            >
              Esqueci minha senha
            </Text>
          </HStack>
        </Box>
        <Box>
          <SocialMidias socialMidias={socialMedia} />
            {prospectConfigs?.ativo && prospectInterestArea?.length > 0 &&
              <VStack spacing={8}>
                <Divider />
                <Text
                  color={theme.colors.white}
                  variant="subtitle1"
                  onPress={() => { props.navigation.navigate(NavigationRoute.Prospect) }}
                >
                  Ainda não é cliente? Cadastre-se aqui!
                </Text>
              </VStack>
            }
        </Box>
      </Box>
      <ModalForgotPassword forgotPassword={props.forgotPassword} />
      <ModalUserSelect authentication={props.authentication} />
      <Text style={styles.footer} color={theme.colors.white} variant="subtitle1">Desenvolvido por Inside Sistemas | v{version}</Text>
    </SafeAreaView>
  )
}
