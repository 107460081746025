import { DateAdapter, DateDiffInDaysParams, DateIsBeforeParams } from '@/data/protocols'
import moment from 'moment'
moment.locale('pt-br')

export class MomentDateAdapter implements DateAdapter {
  diffInDays (dates: DateDiffInDaysParams): number {
    const { startDate, endDate } = dates
    const diff = moment(startDate).startOf('day').diff(moment(endDate).startOf('day'), 'days')

    return diff < 0 ? diff * (-1) : diff
  }

  parse (date: Date | string): Date {
    return moment(date).startOf('day').toDate()
  }

  format (date: Date | string, dateFormat: string = 'DD/MM/YYYY'): string {
    if (!date) {
      return 'Data inválida'
    }

    return moment(date).format(dateFormat)
  }

  today (): Date {
    return moment().toDate()
  }

  daysAgo (days: number, initDate: Date = this.today()): Date {
    return moment(initDate).subtract(days, 'days').toDate()
  }

  yearsAgo (years: number, initDate: Date = this.today()): Date {
    return moment(initDate).subtract(years, 'years').toDate()
  }

  daysLater (days: number, initDate: Date = this.today()): Date {
    return moment(initDate).add(days, 'days').toDate()
  }

  monthAgo (month: number, initDate: Date = this.today()): Date {
    return moment(initDate).subtract(month, 'month').toDate()
  }

  isBefore (dates: DateIsBeforeParams): boolean {
    return moment(dates.startDate).isBefore(dates.endDate)
  }
}
