import React, { useCallback, useRef, useState } from 'react'
import { SafeAreaView, RefreshControl } from 'react-native'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useFocusEffect } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler'
import { MaterialIcons } from '@expo/vector-icons'
import { GetTicketPix, GetTickets, GetTicketsParams, OpenBankSlip, PayWithCreditCard } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useErrorHandler, useMediaQueries, useTheme } from '@/presentation/hooks'
import { ActionsButtons, Box, ScrollToTopButton, PageHeader, CardList } from '@/presentation/components'
import { ticketsState, SideFilters, CardTicket, ModalTicketPix, isOpenFinancialFiltersState, FiltersChipPanel, isLoadingFinancialState, TicketView, AddOtherCreditCard, ticketsFiltersState } from '@/presentation/pages/Financial/components'
import { styles } from './styles'

type FinancialProps = {
  getTickets: GetTickets
  getTicketPix: GetTicketPix
  payWithCreditCard: PayWithCreditCard
  openBankSlip: OpenBankSlip
  dateAdapter: DateAdapter
}

export const Financial: React.FC<FinancialProps> = ({
  dateAdapter,
  getTickets,
  getTicketPix,
  payWithCreditCard,
  openBankSlip
}) => {
  const theme = useTheme()
  const { getClient } = useAuth()
  const handleError = useErrorHandler()
  const { isMobile } = useMediaQueries()
  const [refreshing, setRefreshing] = useState(false)
  const [tickets, setTickets] = useRecoilState(ticketsState)
  const [isOpen, setOpen] = useRecoilState(isOpenFinancialFiltersState)
  const [isLoading, setLoading] = useRecoilState(isLoadingFinancialState)
  const [filters, setTicketsFilters] = useRecoilState(ticketsFiltersState)
  const scrollViewRef = useRef(null)

  const client = getClient()
  const RN_TOTAL_ITEMS_SHOW = isMobile ? 2 : 5

  const currentTicket = tickets?.find(ticket => dateAdapter.parse(ticket.vencimento).getMonth() === dateAdapter.today().getMonth()) ?? tickets[0]
  const orderTickets = tickets?.filter(ticket => ticket.codInterno !== currentTicket.codInterno)

  useFocusEffect(
    useCallback(() => {
      setLoading(true)

      const dataInicial = filters.dataInicio
        ? dateAdapter.format(filters.dataInicio)
        : returnInitialDateFilter()

      const dataFinal = filters.dataFim
        ? dateAdapter.format(filters.dataFim)
        : dateAdapter.format(dateAdapter.daysLater(GetTickets.RN.DEFAULT_DAYS_LATER), 'YYYY-MM-DD')

      setTicketsFilters(prev => ({ ...prev, dataInicio: dataInicial, dataFim: dataFinal }))

      const paraGetTickets: GetTicketsParams = {
        codigoCliente: client.codCliente,
        situacao: filters.situacao,
        dataInicial,
        dataFinal
      }

      getTickets
        .get(paraGetTickets)
        .then(setTickets)
        .catch(handleError)
        .finally(() => setLoading(false))
    }, [])
  )

  const returnInitialDateFilter = () => {
    const defaultInitialdate = dateAdapter.daysAgo(GetTickets.RN.DEFAULT_DAYS_AGO)
    const formattedDefaultInitialdate = dateAdapter.format(defaultInitialdate, 'YYYY-MM-DD')

    if (client.limiteDataRetroativa) {
      const limitDate = dateAdapter.parse(client.limiteDataRetroativa)
      const isBefore = dateAdapter.isBefore({ startDate: defaultInitialdate, endDate: limitDate })
      return isBefore ? dateAdapter.format(limitDate, 'YYYY-MM-DD') : formattedDefaultInitialdate
    }

    return formattedDefaultInitialdate
  }

  const handleRefreshing = (): void => {
    setRefreshing(true)

    const paraGetTickets: GetTicketsParams = {
      codigoCliente: client.codCliente,
      situacao: filters.situacao,
      dataInicial: filters.dataInicio
        ? dateAdapter.format(filters.dataInicio)
        : returnInitialDateFilter(),
      dataFinal: filters.dataFim
        ? dateAdapter.format(filters.dataFim)
        : dateAdapter.format(dateAdapter.daysLater(GetTickets.RN.DEFAULT_DAYS_LATER), 'YYYY-MM-DD')
    }

    getTickets
      .get(paraGetTickets)
      .then(setTickets)
      .catch(handleError)
      .finally(() => setRefreshing(false))
  }

  const scrollToTop = useCallback((): void => {
    if (scrollViewRef.current) {
      (scrollViewRef.current as any).scrollTo({ y: 0, animated: true })
    }
  }, [])

  const handleOnFilter = useCallback((): void => {
    setOpen(true)
  }, [])

  return (
    <SafeAreaView style={styles.container}>
      <SideFilters dateAdapter={dateAdapter} getTickets={getTickets} />
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={styles.scroll}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={handleRefreshing}
          />
        }
      >
        <Box style={styles.content}>
          <PageHeader
            text='Financeiro'
            icon={<MaterialIcons size={24} color={theme.colors.grey} name='attach-money' />}
            openFilters={handleOnFilter}
          />
          <FiltersChipPanel dateAdapter={dateAdapter} />
          <CardList
            isLoading={isLoading}
            isEmpty={!tickets?.length}
            textLoading='Buscando Boletos'
          >
            {currentTicket &&
              <CardTicket
                key={currentTicket.codInterno}
                current={true}
                dateAdapter={dateAdapter}
                ticket={currentTicket}
                getTicketPix={getTicketPix}
                openBankSlip={openBankSlip}
              />
            }
            {orderTickets?.map(ticket =>
              <CardTicket
                key={ticket.codInterno}
                dateAdapter={dateAdapter}
                ticket={ticket}
                getTicketPix={getTicketPix}
                openBankSlip={openBankSlip}
              />
            )}
          </CardList>
        </Box>
      </ScrollView>
      {tickets?.length > RN_TOTAL_ITEMS_SHOW &&
        !isOpen &&
        <ActionsButtons>
          <ScrollToTopButton targetRef={scrollViewRef} scrollToTop={scrollToTop} />
        </ActionsButtons>
      }
      <ModalTicketPix dateAdapter={dateAdapter} getTicketPix={getTicketPix} />
      <AddOtherCreditCard />
      <TicketView dateAdapter={dateAdapter} openBankSlip={openBankSlip} payWithCreditCard={payWithCreditCard} />
    </SafeAreaView>
  )
}
