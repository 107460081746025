import * as React from 'react'
import { CardBase } from '@/presentation/components'

type CardDetailsProps = {
  children: any
  maxWidth?: number | string
  minWidth?: number | string
}

export const CardDetails: React.FC<CardDetailsProps> = ({ children, maxWidth, minWidth }: CardDetailsProps) => {
  return (
    <CardBase maxWidth={maxWidth} minWidth={minWidth} >
      {children}
    </CardBase>
  )
}
