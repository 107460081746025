import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { GetVersions } from '@/domain/usecases'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetVersions implements GetVersions {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<GetVersions.Result | string>
  ) {}

  async get (): Promise<GetVersions.Result> {
    const authParams: HttpRequest = {
      url: `${this.url}/api/versoes`,
      method: 'get'
    }

    const { statusCode, body } = await this.httpClient.request(authParams)

    switch (statusCode) {
      case HttpStatusCode.ok:
        return body as GetVersions.Result
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
