import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { GetDigitalAccepts } from '@/domain/usecases'

export class RemoteGetAcceptDigital implements GetDigitalAccepts {
  constructor (
    private readonly url: string,
    private readonly httpGetClient: HttpClient<GetDigitalAccepts.Result | string>
  ) {}

  async get (params: GetDigitalAccepts.Params): Promise<GetDigitalAccepts.Result> {
    const { body, statusCode } = await this.httpGetClient.request({
      url: `${this.url}/api/portal/lista-aceite-eletronico`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return body as GetDigitalAccepts.Result
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
