import React, { useCallback } from 'react'
import { GestureResponderEvent, Pressable } from 'react-native'
import { useRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AcceptDigitalModel } from '@/domain/models'
import { OpenDocumentAccept, OpenDocumentAcceptParams, OpenUrlDocumentAccept } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { theme } from '@/presentation/styles'
import { useErrorHandler, useNotify } from '@/presentation/hooks'
import { Text, Chip, VStack, Clipboard, MenuActions, IconButton, Menu, Box, Divider, HStack } from '@/presentation/components'
import { currentAcceptDigitalState } from '@/presentation/pages/AceiteDigital/components'
import { styles } from './styles'

type CardAcceptDigitalProps = {
  accept: AcceptDigitalModel
  dateAdapter: DateAdapter
  openUrlDocAccept: OpenUrlDocumentAccept
  openDocumentAccept: OpenDocumentAccept
}

export const CardAcceptDigital: React.FC<CardAcceptDigitalProps> = ({
  accept,
  dateAdapter,
  openUrlDocAccept,
  openDocumentAccept
}) => {
  const notify = useNotify()
  const handleError = useErrorHandler()
  const [currentAcceptDigital, setCurrentAcceptDigital] = useRecoilState(currentAcceptDigitalState)

  const isAuthorized = accept.statusAceite === 'Autorizado'

  const handleOpenUrlDocument = (event: GestureResponderEvent): void => {
    event.preventDefault()

    const viewParams = {
      statusAceite: accept.statusAceite,
      urlAceite: accept.urlAceite
    }

    openUrlDocAccept
      .view(viewParams)
      .then(() => notify.show('O aceite digital foi aberto em uma nova aba'))
      .catch(handleError)
      .finally(handleCloseMenu)
  }

  const handleOpenDocumentAccept = (event: GestureResponderEvent): void => {
    event.preventDefault()

    const openParams: OpenDocumentAcceptParams = {
      statusAceite: accept.statusAceite,
      codigoAceite: accept.idAceite
    }

    openDocumentAccept
      .open(openParams)
      .then(() => notify.show('O documento foi aberto em uma nova aba'))
      .catch(handleError)
      .finally(handleCloseMenu)
  }

  const handleCopyHash = (hash: string): void => {
    Clipboard.setStringAsync(hash)
    notify.show('Hash do Aceite Digital copiado com sucesso')
  }

  const handleOpenMenu = (idAceite: number): void => {
    setCurrentAcceptDigital(idAceite)
  }

  const handleCloseMenu = useCallback((): void => {
    setCurrentAcceptDigital(-1)
  }, [])

  const colorType = {
    Orçamento: theme.colors.primary,
    Contrato: theme.colors.info,
    Aditivo: theme.colors.pink,
    default: theme.colors.primary
  }[accept.tipoAceite]

  const containerStyles = {
    ...styles.container,
    backgroundColor: theme.colors.white,
    color: theme.colors.greyLighten
  }

  const typeChipContainer = {
    ...styles,
    backgroundColor: colorType
  }

  const statusChipContainer = {
    ...styles,
    backgroundColor: !isAuthorized ? theme.colors.secondary : theme.colors.success
  }

  return (
    <Box style={containerStyles}>
      <HStack items='center' justify='between' spacing={8} mb={8}>
        <Text variant='subtitle1' style={styles.headerTitle} testID='aceite-email'>
          {accept.emailEnvio}
        </Text>
        <MenuActions
          key={`menu-${accept.idAceite}`}
          visible={currentAcceptDigital >= 0 && currentAcceptDigital === accept.idAceite}
          onDismiss={handleCloseMenu}
          anchor={
            <IconButton
              onPress={() => handleOpenMenu(accept.idAceite)}
              style={{ width: 32, height: 32 }}
              icon={(props: any) => <MaterialCommunityIcons name="dots-vertical" {...props} />}
              testID='aceite-digital-opcoes'
            />
          }>
            {isAuthorized &&
              <Menu.Item
                onPress={handleOpenDocumentAccept}
                testID='aceite-visualizar-documento'
                title={isAuthorized ? 'Visualizar documento' : 'Aceitar/Recusar'}
                leadingIcon={props => <MaterialCommunityIcons name='link' {...props} />}
              />
            }
            <Menu.Item
              onPress={handleOpenUrlDocument}
              testID='aceite-visualizar-certificado'
              title={isAuthorized ? 'Visualizar certificado' : 'Aceitar/Recusar'}
              leadingIcon={props => <MaterialCommunityIcons name="link" {...props} />}
            />
        </MenuActions>
      </HStack>

      <VStack spacing={8} style={{ flex: 1 }}>
        <Divider style={styles.divider} />
        <HStack justify='between'>
          <Text variant='subtitle1' style={styles.contentTextWithChip} testID='aceite-tipo'>
            <span style={styles.contentTitle}>
             {'Tipo '}
            </span>
            <Chip
              text={accept.tipoAceite}
              containerStyle={typeChipContainer}
            />
          </Text>
          <Text variant='subtitle1' style={styles.contentTextWithChip} testID='aceite-status'>
            <span style={styles.contentTitle}>
              {'Status '}
            </span>
            <Chip
              text={accept.statusAceite}
              containerStyle={statusChipContainer}
            />
          </Text>
        </HStack>
        <Text variant='subtitle1' style={styles.contentText}>
          <span style={styles.contentTitle}>
            {'Envio: '}
          </span>
          {dateAdapter.format(accept.dataEnvio, 'DD/MM/YYYY HH:mm:ss')}
        </Text>
        {
          accept.aceiteHashProcesso !== '' &&
          <HStack items='center' spacing={8}>
            <MaterialCommunityIcons size={18} color={theme.colors.grey} name='shield-lock' />
            <Text variant='subtitle1' style={styles.contentText}>
              <span style={styles.contentTitle}>
                {'Hash: '}
              </span>
              {accept.aceiteHashProcesso}
            </Text>
            <Pressable onPress={() => handleCopyHash(accept.aceiteHashProcesso)} testID='copiar-hash'>
              <MaterialCommunityIcons
                name='content-copy'
                size={18}
                color={theme.colors.greyLight}
              />
            </Pressable>
          </HStack>
        }
      </VStack>
    </Box>
  )
}
