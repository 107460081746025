import { AcceptDigitalModel } from '@/domain/models'

export interface GetDigitalAccepts {
  get: (params: GetDigitalAccepts.Params) => Promise<GetDigitalAccepts.Result>
}

export namespace GetDigitalAccepts {
  export type Params = {
    codCliente: number
    dtInicial: string
    dtFinal: string
    status: 'Pendentes' | 'Autorizados' | 'Ambos'
  }

  export type Result = AcceptDigitalModel[]
  export const RN = {
    DEFAULT_DAYS_LATER: 90,
    DEFAULT_DAYS_AGO: 120,
    DEFAULT_STATUS: 'Ambos' as 'Pendentes' | 'Autorizados' | 'Ambos'
  }
}
