import { AuthenticationAdminParams, AuthenticationAdminResult, AuthenticationAdmin } from '@/domain/usecases'
import { ForbiddenError, InvalidCredentialsError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols/http'
import { AuthenticationModel } from '@/domain/models'

export class RemoteAuthenticationAdmin implements AuthenticationAdmin {
  constructor (
    private readonly url: string,
    private readonly identificadorAplicacao: string,
    private readonly httpClient: HttpClient<AuthenticationAdminResult>
  ) {}

  async auth (params: AuthenticationAdminParams): Promise<AuthenticationModel> {
    const authParams: HttpRequest = {
      url: `${this.url}/api/v1/autenticacao/autenticar`,
      method: 'post',
      body: {
        ...params,
        identificadorAplicacao: this.identificadorAplicacao
      },
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    const { statusCode, body } = await this.httpClient.request(authParams)

    switch (statusCode) {
      case HttpStatusCode.ok: return this.mapToModel(body)
      case HttpStatusCode.forbidden: throw new ForbiddenError(body?.message)
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError(body?.message)
      default: throw new UnexpectedError()
    }
  }

  mapToModel (authenticationAdmin: AuthenticationAdminResult): AuthenticationModel {
    return {
      ...authenticationAdmin.data,
      access_token: authenticationAdmin.data.token
    }
  }
}
