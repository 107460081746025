import React, { useCallback, useEffect } from 'react'
import { GestureResponderEvent } from 'react-native'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Box, Divider, DatePicker, InputField, SideFilter, Snackbar, Button } from '@/presentation/components'
import { useAuth, useErrorHandler, useMediaQueries, useTheme } from '@/presentation/hooks'
import { SelectStatusTicketFilter, invalidDateState, isLoadingFinancialState, isOpenFinancialFiltersState, ticketsFiltersState, ticketsState } from '@/presentation/pages/Financial/components'
import { GetTickets, GetTicketsParams, GetTicketSituation } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'

type DateParams = {
  dataInicio: string
  dataFim: string
}

export type FiltrosFatura = {
  situacao: GetTicketSituation
  dataInicio: string
  dataFim: string
}

type SideFiltersProps = {
  getTickets: GetTickets
  dateAdapter: DateAdapter
}

export const MARGIN_MODALS = 30

export const SideFilters: React.FC<SideFiltersProps> = ({ getTickets, dateAdapter }) => {
  const { getClient } = useAuth()
  const [isOpen, setOpen] = useRecoilState(isOpenFinancialFiltersState)
  const [filters, setFilters] = useRecoilState(ticketsFiltersState)
  const [isLoading, setLoading] = useRecoilState(isLoadingFinancialState)
  const [invalidDate, setInvalidDate] = useRecoilState(invalidDateState)
  const errorHandler = useErrorHandler()
  const setTickets = useSetRecoilState(ticketsState)
  const { isMobile } = useMediaQueries()
  const theme = useTheme()

  const client = getClient()

  useEffect(() => {
    setFilters({
      situacao: GetTickets.RN.DEFAULT_STATUS,
      dataInicio: returnInitialDateFilter(),
      dataFim: dateAdapter.format(dateAdapter.daysLater(GetTickets.RN.DEFAULT_DAYS_LATER), 'YYYY-MM-DD')
    })
  }, [])

  const returnInitialDateFilter = () => {
    const defaultInitialdate = dateAdapter.daysAgo(GetTickets.RN.DEFAULT_DAYS_AGO)
    const formattedDefaultInitialdate = dateAdapter.format(defaultInitialdate, 'YYYY-MM-DD')

    if (client.limiteDataRetroativa) {
      const limitDate = dateAdapter.parse(client.limiteDataRetroativa)
      const isBefore = dateAdapter.isBefore({ startDate: defaultInitialdate, endDate: limitDate })
      return isBefore ? dateAdapter.format(limitDate, 'YYYY-MM-DD') : formattedDefaultInitialdate
    }

    return formattedDefaultInitialdate
  }

  const handleGetTickets = (event: GestureResponderEvent): void => {
    event.preventDefault()
    setLoading(true)

    const paramsGetTicket: GetTicketsParams = {
      codigoCliente: client.codCliente,
      situacao: filters.situacao,
      dataInicial: dateAdapter.format(filters.dataInicio, 'YYYY-MM-DD'),
      dataFinal: dateAdapter.format(filters.dataFim, 'YYYY-MM-DD')
    }

    getTickets
      .get(paramsGetTicket)
      .then(setTickets)
      .catch(errorHandler)
      .finally(() => setLoading(false))

    setOpen(false)
  }

  const handleChangeDate = useCallback((field: keyof DateParams, date: Date): void => {

    if (client.limiteDataRetroativa && field === 'dataInicio') {
      const filterParsedDate = dateAdapter.parse(client.limiteDataRetroativa)
      const isValid = dateAdapter.isBefore({ startDate: date, endDate: filterParsedDate })

      if (isValid) return setInvalidDate(true)

      setInvalidDate(false)
    }

    setFilters(currentFilters => ({
      ...currentFilters,
      [field]: date.toString()
    }))
  }, [])

  const handleResetState = (): void => {
    setFilters({
      situacao: GetTickets.RN.DEFAULT_STATUS,
      dataInicio: dateAdapter.format(dateAdapter.daysAgo(GetTickets.RN.DEFAULT_DAYS_AGO), 'YYYY-MM-DD'),
      dataFim: dateAdapter.format(dateAdapter.daysLater(GetTickets.RN.DEFAULT_DAYS_LATER), 'YYYY-MM-DD')
    })
  }

  const handleClose = (): void => {
    setInvalidDate(false)
    setOpen(false)
  }

  const closeLimitDateAlert = (event: GestureResponderEvent): void => {
    event.preventDefault()
    setInvalidDate(false)
  };

  return (
    <SideFilter.Drawer
      open={isOpen}
      onClose={handleClose}
      loading={isLoading}
      onSubmit={handleGetTickets}
      resetState={handleResetState}
    >
      <Box testID='data-filtro'>
        <Snackbar
          message={`Data inicial não pode ser anterior a ${dateAdapter.format(client.limiteDataRetroativa, 'DD/MM/YYYY')}`}
          testID={`notificacao-date-filter-financial`}
          action={
            <Button
              variant="text"
              title="FECHAR"
              color={theme.colors.greyLight}
              onPress={closeLimitDateAlert}
              compact
            />
          }
          style={{
            backgroundColor: theme.colors.error,
            width: isMobile ? '100%' : '500px',
            display: invalidDate ? 'flex' : 'none'
          }}
        />
        <SideFilter.Title title='Período de Vencimento' />
        <InputField>
          <DatePicker
            label='De'
            value={filters.dataInicio ? dateAdapter.parse(filters.dataInicio) : undefined}
            onChange={(date) => handleChangeDate('dataInicio', date ?? dateAdapter.daysAgo(GetTickets.RN.DEFAULT_DAYS_AGO))}
            dateAdapter={dateAdapter}
          />
          <Divider />
          <DatePicker
            label='Até'
            value={filters.dataFim ? dateAdapter.parse(filters.dataFim) : undefined}
            onChange={(date) => handleChangeDate('dataFim', date ?? dateAdapter.daysLater(GetTickets.RN.DEFAULT_DAYS_LATER))}
            dateAdapter={dateAdapter}
          />
        </InputField>
      </Box>
      <Box>
        <SideFilter.Title title='Status da Fatura' />
        <SelectStatusTicketFilter />
      </Box>
    </SideFilter.Drawer>
  )
}
