import { GetClientByCode } from '@/domain/usecases/'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { ClientModel } from '@/domain/models'

export class RemoteGetClientByCode implements GetClientByCode {
  constructor (
    private readonly url: string,
    private readonly httpCliente: HttpClient<ClientModel>
  ) {}

  async getByCode (params: GetClientByCode.Params): Promise<ClientModel> {
    const treatedParams = {
      codigoCliente: params.codigoCliente,
      tokenDispositivo: params.tokenDispositivo
    }

    const { statusCode, body } = await this.httpCliente.request({
      method: 'post',
      url: `${this.url}/api/portal/buscar-cliente`,
      body: treatedParams
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
