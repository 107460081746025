import React from 'react'
import { RedesSociais } from '@/presentation/pages/RedesSociais'
import { makeGetRedesSociais, makeGetUnits, makeSaveRedesSociais } from '@/main/factories/usecases'

export const makeRedesSociaisFactory = (): JSX.Element => {
  return <RedesSociais
    saveRedesSociais={makeSaveRedesSociais()}
    getRedesSociais={makeGetRedesSociais()}
    getUnits={makeGetUnits()}
  />
}
