import { atom } from 'recoil'
import { RedesSociaisModel } from '@/domain/models'

export const socialMediaState = atom({
  key: 'socialMediaState',
  default: {
    codUnidade: 0,
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    linkedin: '',
    whatsapp: '',
    telegram: '',
    tiktok: '',
    phone: ''
  } as RedesSociaisModel
})
