import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { RemotyUserResult } from '@/domain/models'
import { GetRemotyUsers } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetRemotyUserEvents implements GetRemotyUsers {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RemotyUserResult | string>
  ) { }

  async getUsers (params: GetRemotyUsers.Params): Promise<GetRemotyUsers.Result> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/portal/remoty/listaUsuarios`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return this.maptoGeneric(body as RemotyUserResult)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }

  maptoGeneric (users: RemotyUserResult): GetRemotyUsers.Result {
    return users.map(user => ({
      nome: user.nmUserPassword
    }))
  }
}
