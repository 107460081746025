import React from 'react'
import { Card } from 'react-native-paper'
import { useMediaQueries, useTheme } from '@/presentation/hooks'
import { styles } from './styles'

type CardBaseProps = {
  children: JSX.Element | JSX.Element[]
  maxWidth?: number | string
  minWidth?: number | string
}

export const CardBase: React.FC<CardBaseProps> = ({
  children,
  maxWidth,
  minWidth
}) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()

  return (
    <Card
      style={{
        ...styles.cardBase,
        backgroundColor: theme.colors.backgroundCard,
        shadowOpacity: 0.3,
        minWidth: minWidth ?? 280,
        maxWidth: maxWidth ?? 600,
        marginBottom: isMobile ? 12 : 24,
        padding: isMobile ? 4 : 8,
        flexWrap: 'wrap'
      }}
    >
      <Card.Content style={styles.cardContent}>
        {children}
      </Card.Content>
    </Card>
  )
}
