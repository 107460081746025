import React, { useCallback, useEffect } from 'react'
import { FontAwesome5 as Icon } from '@expo/vector-icons'
import { useFocusEffect } from '@react-navigation/native'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { RedesSociaisModel } from '@/domain/models'
import { GetRedesSociais, GetUnits, SaveRedesSociais, SaveRedesSociaisParams } from '@/domain/usecases'
import { useErrorHandler, useNotify, useThemeClient, useTheme } from '@/presentation/hooks'
import { Box, Button, Text, TextInput, Footer, socialMediaState, currentUnitState, unitsState, HStack, UnitsSelector, Loading } from '@/presentation/components'
import { styles } from './styles'
import { SafeAreaView } from 'react-native'
import { isLoadingSocialMidiaState } from '@/presentation/pages/RedesSociais/components/atom'

type RedesSociasProps = {
  saveRedesSociais: SaveRedesSociais
  getRedesSociais: GetRedesSociais
  getUnits: GetUnits
}

export const RedesSociais: React.FC<RedesSociasProps> = ({ saveRedesSociais, getRedesSociais, getUnits }) => {
  const notify = useNotify()
  const handleError = useErrorHandler()
  const [state, setState] = useRecoilState(socialMediaState)
  const [currentUnit, setCurrentUnit] = useRecoilState(currentUnitState)
  const [isLoadingSocialMidia, setIsLoadingSocialMidia] = useRecoilState(isLoadingSocialMidiaState)
  const setUnits = useSetRecoilState(unitsState)
  const { getThemeClient } = useThemeClient()
  const themeClient = getThemeClient()
  const theme = useTheme()

  useEffect(() => {
    setIsLoadingSocialMidia(true)
    getUnits
      .getAll()
      .then(async units => {
        if (units.length) {
          setUnits(units)
          setCurrentUnit(units.at(0))
        }
      })
      .catch(handleError)
      .finally(() => setIsLoadingSocialMidia(false))
  }, [])

  useFocusEffect(
    useCallback(() => {
      if (!currentUnit?.codigo) { return }
      setIsLoadingSocialMidia(true)

      const getRedesSociaisParams: GetRedesSociais.Params = {
        codUnidade: currentUnit.codigo
      }

      getRedesSociais
        .getRedesSociais(getRedesSociaisParams)
        .then(setState)
        .catch(handleError)
        .finally(() => setIsLoadingSocialMidia(false))
    }, [currentUnit])
  )

  const handleChange = (name: keyof RedesSociaisModel, value: string): void => {
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSave = (): void => {
    const saveParams: SaveRedesSociaisParams = {
      facebook: state.facebook,
      instagram: state.instagram,
      linkedin: state.linkedin,
      phone: state.phone,
      telegram: state.telegram,
      tiktok: state.tiktok,
      whatsapp: state.whatsapp,
      youtube: state.youtube,
      codUnidade: currentUnit.codigo
    }

    void saveRedesSociais.save(saveParams)
      .then(() => notify.show('Sucesso! Os links foram salvos!'))
      .catch(handleError)
  }

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Box>
          <HStack justify='center' items='center' style={{
            backgroundColor: `${themeClient.mainColor}CC`
          }}>
            <Text variant="subtitle1" style={{ ...styles.headerText, color: theme.colors.white }} >
              Redes Sociais da Unidade
            </Text>
            <UnitsSelector disabled={isLoadingSocialMidia} />
          </HStack>
          <Box>
            {isLoadingSocialMidia
              ? <Loading text='Buscando Redes Sociais' />
              :
              <Box>
                <Box style={styles.container}>
                  <TextInput
                    value={state.phone}
                    onChangeText={(text) => handleChange('phone', text)}
                    leading={(props) => <Icon as Icon name='phone' {...props} />}
                    label='Número do Telefone'
                  />
                  <TextInput
                    value={state.whatsapp}
                    onChangeText={(text) => handleChange('whatsapp', text)}
                    leading={(props) => <Icon as Icon name='whatsapp' {...props} />}
                    label='Whatsapp'
                  />
                  <TextInput
                    value={state.facebook}
                    onChangeText={(text) => handleChange('facebook', text)}
                    leading={(props) => <Icon as Icon name='facebook' {...props} />}
                    label='Link do Facebook'
                  />
                  <TextInput
                    value={state.linkedin}
                    onChangeText={(text) => handleChange('linkedin', text)}
                    leading={(props) => <Icon as Icon name='linkedin' {...props} />}
                    label='Link do Linkedin'
                  />
                  <TextInput
                    value={state.instagram}
                    onChangeText={(text) => handleChange('instagram', text)}
                    leading={(props) => <Icon as Icon name='instagram' {...props} />}
                    label='Link do Instagram'
                  />
                  <TextInput
                    value={state.tiktok}
                    onChangeText={(text) => handleChange('tiktok', text)}
                    leading={(props) => <Icon as Icon name='tiktok' {...props} />}
                    label='Link do Tiktok'
                  />
                  <TextInput
                    value={state.telegram}
                    onChangeText={(text) => handleChange('telegram', text)}
                    leading={(props) => <Icon as Icon name='telegram' {...props} />}
                    label='Link do Telegram'
                  />
                  <TextInput
                    value={state.youtube}
                    onChangeText={(text) => handleChange('youtube', text)}
                    leading={(props) => <Icon as Icon name='youtube' {...props} />}
                    label='Link do Youtube'
                  />
                </Box>
                <Footer>
                  <Box style={styles.footer}>
                    <Button variant='text' title='Gravar' onPress={handleSave} />
                  </Box>
                </Footer >
              </Box>
            }
          </Box>
        </Box>
      </SafeAreaView>
    </>
  )
}
