import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons'
import { BANK, TicketModel } from '@/domain/models'
import { OpenBankSlip, OpenBankSlipParams } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useNotify, useTheme } from '@/presentation/hooks'
import { Button, Clipboard, VStack, PixIcon } from '@/presentation/components'
import { isLoadingTicketViewState, isOpenCreditCardsState, isOpenTicketPixState, selectedTicketPixState, ticketViewState, isOpenTicketViewState } from '@/presentation/pages/Financial/components'

type TicketActionsViewProps = {
  openBankSlip: OpenBankSlip
  dateAdapter: DateAdapter
}

const MARGIN_ITEMS = 8

export const TicketActions: React.FC<TicketActionsViewProps> = ({ dateAdapter, openBankSlip }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { getClient } = useAuth()
  const [ticketView, setTicketView] = useRecoilState(ticketViewState)
  const setOpenTicketViewState = useSetRecoilState(isOpenTicketViewState)
  const setLoading = useSetRecoilState(isLoadingTicketViewState)
  const setOpenPix = useSetRecoilState(isOpenTicketPixState)
  const setOpenCreditCards = useSetRecoilState(isOpenCreditCardsState)
  const setCurrentTicketPix = useSetRecoilState(selectedTicketPixState)

  const client = getClient()

  const handleCopyBarCode = (): void => {
    Clipboard.setStringAsync(ticketView.linhaDigitavel)
    handleClose()
    notify.show('Código de barras copiado com sucesso')
  }

  const handleOpenCreditCard = (): void => setOpenCreditCards(true)

  const handleOpenPDFBankSlip = (): void => {
    setLoading(true)

    const params: OpenBankSlipParams = {
      codigoCliente: ticketView?.cliente,
      nossoNumero: ticketView?.nossoNumero,
      codBanco: ticketView?.codBanco,
      dtVencimento: ticketView?.vencimento,
      registrado: ticketView?.registrado,
      linkBoleto: ticketView?.linkBoleto,
      novaVersao: client.novaVersao
    }

    openBankSlip
      .openPDF(params)
      .then(() => notify.show(`A fatura Nº ${ticketView?.numero} e vencimento ${dateAdapter.format(ticketView?.vencimento)} foi aberta em uma nova aba`))
      .catch(error => notify.show(error, { variant: 'error' }))
      .finally(() => {
        handleClose()
        setLoading(false)
      })
  }

  const handleOpenPix = (ticket: TicketModel): void => {
    handleClose()
    setOpenPix(true)
    if (ticket) {
      setCurrentTicketPix(ticket)
    }
  }

  const handleClose = (): void => {
    setTicketView(null as unknown as TicketModel)
    setOpenTicketViewState(false)
  }

  return (
    <VStack mt={MARGIN_ITEMS} mb={16} spacing={MARGIN_ITEMS}>
      {([BANK.BoletoInside].includes(ticketView.codBanco) || ticketView.pixUrl) &&
        <Button
          variant='outlined'
          title='Gerar PIX copia e cola'
          color={theme.colors.greyDark}
          leading={<PixIcon height={24} color={theme.colors.greyDark} />}
          onPress={() => handleOpenPix(ticketView)}
        />
      }
      {ticketView?.permiteCartaoCredito &&
        ticketView?.atraso <= 0 &&
        !ticketView?.dupProtestada &&
          <Button
            variant='outlined'
            color={theme.colors.greyDark}
            title='Cartão de Crédito'
            leading={props => <MaterialIcons name='credit-card' {...props} size={24} />}
            onPress={handleOpenCreditCard}
          />
      }
      {!!ticketView?.linhaDigitavel && !!ticketView?.nossoNumero &&
        <Button
          variant='outlined'
          title='Copiar código de barras'
          color={theme.colors.greyDark}
          leading={props => <MaterialCommunityIcons name='content-copy' {...props} size={24} />}
          onPress={handleCopyBarCode}
        />
      }
      {(!!ticketView?.nossoNumero || !!ticketView?.linkBoleto) &&
        <Button
          variant='outlined'
          color={theme.colors.greyDark}
          title={`${ticketView?.registrado ? 'Visualizar' : 'Imprimir'} Boleto`}
          leading={props => <MaterialIcons name='arrow-downward' {...props} size={24} />}
          onPress={handleOpenPDFBankSlip}
        />
      }
    </VStack>
  )
}
